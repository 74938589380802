<template>
  <moe-page title="硬件测试记录">
    <moe-inquire @search="testRecordSearch">
      <el-form-item label="设备名称">
        <el-input v-model.trim="testRecordParams.modelName" placeholder="输入设备名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="设备型号">
        <el-input v-model.trim="testRecordParams.productModel" placeholder="输入设备型号" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="设备mac地址">
        <el-input v-model.trim="testRecordParams.mac" placeholder="输入设备mac地址" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="设备序列号">
        <el-input v-model.trim="testRecordParams.serial" placeholder="输入设备序列号" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="设备ID">
        <el-input v-model.trim="testRecordParams.uid" placeholder="输入设备ID" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="测试时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([testRecordParams.startTime, testRecordParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
      <el-form-item label="检测报告">
        <moe-select type="testRecordstatus" v-model="testRecordParams.pass" placeholder="选择检测报告" clearable></moe-select>
      </el-form-item>
    </moe-inquire>

    <moe-table ref="testRecordTable" url="/factoryTest/result/list" :params="testRecordParams" :number-show="false">
      <el-table-column label="设备名称" prop="modelName" :show-overflow-tooltip="true" />
      <el-table-column label="设备型号" prop="productModel" :show-overflow-tooltip="true" />
      <el-table-column label="设备mac地址" prop="mac" :show-overflow-tooltip="true" min-width="150" />
      <el-table-column label="设备序列号" prop="serial" :show-overflow-tooltip="true" min-width="150" />
      <el-table-column label="设备ID" prop="uid" :show-overflow-tooltip="true" />
      <el-table-column label="固件版本" prop="firmwareVersion" :show-overflow-tooltip="true" />
      <el-table-column label="测试时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="检测报告" min-width="150">
        <template slot-scope="{ row }">
          <div :class="formatterPass(row.resultList) === '-' ? '' : formatterPass(row.resultList) === '通过' ? 'color-success' : 'color-danger'">
            {{ formatterPass(row.resultList) }}
          </div>
          <el-button type="primary" size="mini" icon="el-icon-view" @click="handleDetail(row)">查看详情</el-button>
        </template>
      </el-table-column>
      <el-table-column label="测试人员" prop="tester" />
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-view" @click="handleLog(row)">日志</el-button>
        </template>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="检测报告" width="50%" @close="handleDialogClose()">
      <moe-card>
        <template #header>
          {{ dialogTitle }}
        </template>
        <moe-table :mode="false" :params="{ pageNum: 1 }" :data="reportList" :number-show="false">
          <el-table-column label="测试项目" prop="title" />
          <el-table-column label="测试结果" min-width="150">
            <div :class="$moe_format.getTestRecordstatus(row.value).class" slot-scope="{ row }">
              {{ $moe_format.getTestRecordstatus(row.value).title }}
            </div>
          </el-table-column>
        </moe-table>
      </moe-card>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'HardwareTesttestRecordList',
  data() {
    return {
      testRecordParams: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',    //测试开始时间
        endTime: '',      //测试结束时间
        mac: '',          //设备mac
        modelName: '',    //设备名称
        productModel: '', //设备型号
        serial: '',       //设备序列号
        uid: '',          //设备id
        pass: '',         //是否通过
      },
      dateTime: [],
      showDialog: false,
      dialogTitle: '',
      showDialog2: false,
      reportList: [],
    }
  },
  methods: {
    testRecordSearch(isSearch) {
      if (!isSearch) {
        this.testRecordParams = {
          pageNum: 1,
          pageSize: 10,
          startTime: '',
          endTime: '',
          mac: '',
          modelName: '',
          productModel: '',
          serial: '',
          uid: '',
          pass: '',
        };

        this.dateTime = [];
      }

      this.$refs['testRecordTable'].searchData();
    },
    /** 查看检测报告 */
    handleDetail({ modelName, resultList }) {
      this.dialogTitle = modelName;
      this.reportList = this.$moe_lodash.cloneDeepData(resultList);
      this.showDialog = true;
    },
    handleDialogClose() {
      this.showDialog = false;
      this.reportList.splice(0);
      this.dialogTitle = '';
    },
    /** 查看日志 */
    handleLog({ log }) {
      window.open(
        log,
        '_black'
      );
    },
    formatterPass(list) {
      if (!list) {
        return '-'
      }

      let passList = list.filter(({ value }) => !value)
      if (passList.length) {
        return `${passList.length}项未通过`
      } else {
        return '通过'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>